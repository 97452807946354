import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import "./modal.css"; // You can define your modal styles in this file

const Modal = ({
    images,
    selectedImageIndex,
    showPreviousImage,
    showNextImage,
    closeModal,
}) => {
    useEffect(() => {
        // Add event listeners for keyboard arrow navigation
        const handleKeyPress = (event) => {
            if (event.key === "ArrowLeft") {
                showPreviousImage();
            } else if (event.key === "ArrowRight") {
                showNextImage();
            }
        };

        document.addEventListener("keydown", handleKeyPress);

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [showPreviousImage, showNextImage]);
    return (
        <div className="modal">
            <FontAwesomeIcon
                icon={faTimes}
                className="close-button"
                onClick={closeModal}
            />

            <FontAwesomeIcon
                icon={faChevronLeft}
                className="prev-button"
                onClick={showPreviousImage}
            />
            <FontAwesomeIcon
                icon={faChevronRight}
                className="next-button"
                onClick={showNextImage}
            />

            <div className="modal-content">
                <img
                    src={images[selectedImageIndex].src}
                    alt={images[selectedImageIndex].description}
                    className="fullScreenImage"
                    onClick={closeModal}
                />
                <div
                    className="description"
                    dangerouslySetInnerHTML={{
                        __html: images[selectedImageIndex].description,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default Modal;
