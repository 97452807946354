import React from "react";
import "./bio.css";

const Bio = () => {
    return (
        <div className="bio">
            <div className="text">
                <div className="intro">
                    <p>
                        Johanna Odersky (b. 1993) is a Swiss and German artist
                        based in Berlin. She graduated from the Hochschule für
                        Bildende Künste – Städelschule, studying under Judith
                        Hopf. Her visual and audio works have been shown in art
                        spaces and music venues such as Kunsthalle Lingen;
                        Centre d’Art Contemporain, Geneva; Portikus, Frankfurt
                        am Main; Arsenic, Lausanne; Kölnischer Kunstverein,
                        Cologne; Berghain, Berlin.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Bio;
