import { React, useState } from "react";
import { projectsData } from "../projectsData";
import "./theitch.css";
import Modal from "./modal";

const Itch = ({ selectedProject }) => {
    const selectedProjectData = projectsData.find(
        (project) => project.title === selectedProject
    );

    const images = selectedProjectData ? selectedProjectData.images : [];
    const collab = selectedProjectData ? selectedProjectData.with : "";
    const video = selectedProjectData ? selectedProjectData.video : "";
    const text = selectedProjectData ? selectedProjectData.text : "";

    const curation = selectedProjectData ? selectedProjectData.curation : "";
    const location = selectedProjectData ? selectedProjectData.location : "";
    const link = selectedProjectData ? selectedProjectData.link : "";
    const description = selectedProjectData
        ? selectedProjectData.description
        : "";

    // State to track the index of the currently selected image
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const openModal = (index) => {
        setSelectedImageIndex(index);
    };

    const closeModal = () => {
        setSelectedImageIndex(null);
    };

    // Function to show the next image
    const showNextImage = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    // Function to show the previous image
    const showPreviousImage = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="itch">
            {video && (
                <div className="video-container">
                    <iframe
                        src={video[0].src}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    ></iframe>

                    {video[0].description && (
                        <div
                            className="video-description"
                            dangerouslySetInnerHTML={{
                                __html: video[0].description,
                            }}
                        ></div>
                    )}
                </div>
            )}
            <div className="intro">
                {selectedProject && (
                    <h2>
                        <span className="project-title">{selectedProject}</span>
                    </h2>
                )}
                {collab && <h2>with {collab}</h2>}
                <br></br>
                {curation && <h2>Curated by {curation}</h2>}
                {location ? (
                    <h2>
                        at
                        <a href={link}> {location}</a>
                    </h2>
                ) : (
                    <h2>{description}</h2>
                )}
            </div>
            <br></br>
            {text && (
                <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: text }}
                ></div>
            )}
            <div className="iku-link">
                <a
                    href="https://iku.hearnow.com/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src="/images/itch/itch21.jpg"
                        alt="iku-the ich album"
                    />
                    <p>
                        Listen to the The Itch <em>here.</em>
                    </p>
                </a>
            </div>

            {images && (
                <div className="gallery-container">
                    <ul className="itch-gallery">
                        {images.map((image, index) => (
                            <li>
                                <img
                                    key={index}
                                    src={image.src}
                                    alt={`from project`}
                                    className="itch-image"
                                    onClick={() => openModal(index)}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {selectedImageIndex !== null && (
                <Modal
                    images={images}
                    selectedImageIndex={selectedImageIndex}
                    showPreviousImage={showPreviousImage}
                    showNextImage={showNextImage}
                    closeModal={closeModal}
                />
            )}
        </div>
    );
};

export default Itch;
