import React from "react";
import "./projects.css";
import { Link } from "react-router-dom";
import { projectsData } from "../projectsData";

const ProjectsList = ({ handleProjectClick }) => {
    const handleLinkClick = (projectName) => {
        handleProjectClick(projectName);
    };

    const slugify = (text) => {
        return text
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace spaces with dashes
            .replace(/[^\w-]+/g, "") // Remove all non-word characters
            .replace(/--+/g, "-") // Replace multiple dashes with a single dash
            .replace(/^-+|-+$/g, ""); // Remove leading and trailing dashes
    };

    // Generate slugs for projects
    const projectsWithSlug = projectsData.map((project) => ({
        ...project,
        slug: slugify(project.title),
    }));

    // Filter out the first "About" project
    const remainingProjects = projectsWithSlug.slice(1);

    return (
        <div className="projects">
            <ul>
                {remainingProjects.map((project) => (
                    <li key={project.title}>
                        <Link
                            to={`/${project.slug}`}
                            onClick={() => handleLinkClick(project.title)}
                            className="project"
                        >
                            {project.location ? (
                                <p>
                                    <span className="project-title">
                                        {project.title}
                                    </span>
                                    <br />
                                    {project.with && (
                                        <>
                                            <p>with {project.with}</p>
                                            <br />
                                        </>
                                    )}
                                    at {project.location} <br />
                                    {project.dates}
                                </p>
                            ) : (
                                <p>
                                    <span className="project-title">
                                        {project.title}
                                    </span>
                                    <br></br>
                                    {project.with && (
                                        <>
                                            <p>with {project.with}</p>
                                        </>
                                    )}
                                    {project.description && (
                                        <>
                                            <p> {project.description}</p>
                                            <br />
                                        </>
                                    )}
                                    {project.dates && (
                                        <p>
                                            {project.dates} <br />
                                        </p>
                                    )}
                                </p>
                            )}
                            <img src={project.cover} alt={project.title} />
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ProjectsList;
