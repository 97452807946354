import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./iku.css";
import { projectsData } from "../projectsData";

const IkuComponent = ({ selectedProject }) => {
    const selectedProjectData = projectsData.find(
        (project) => project.title === selectedProject
    );

    const [images, setImages] = useState([]);

    // State to track the index of the currently selected image
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    // Modal
    const openModalOrUrl = (index) => {
        const selectedImage = images[index];
        if (selectedImage && selectedImage.url) {
            window.open(selectedImage.url, "_blank"); // Open URL in a new tab
        } else {
            setSelectedImageIndex(index);
        }
    };

    const closeModal = () => {
        setSelectedImageIndex(null);
    };

    useEffect(() => {
        if (selectedProjectData) {
            const randomizedImages = selectedProjectData.images.sort(
                () => Math.random() - 0.5
            );
            setImages(randomizedImages);
        }
    }, [selectedProjectData]);

    return (
        <div id="iku-component">
            {images && (
                <div className="gallery-container">
                    <ul className="iku-gallery">
                        {images.map((image, index) => (
                            <li>
                                <img
                                    key={index}
                                    src={image.src}
                                    alt={`from project`}
                                    className="iku-image"
                                    onClick={() => openModalOrUrl(index)}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {selectedImageIndex !== null && (
                <div className="modal">
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="close-button"
                        onClick={closeModal}
                    />

                    <div className="modal-content">
                        <img
                            src={images[selectedImageIndex].src}
                            alt={images[selectedImageIndex].description}
                            className="fullScreenImage"
                            onClick={closeModal}
                        />
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{
                                __html: images[selectedImageIndex].description,
                            }}
                        ></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default IkuComponent;
/*{images && (
                <div className="image-grid">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image.src}
                            alt={`from project`}
                            onClick={() => openModalOrUrl(index)}
                            className={image.orientation}
                        />
                    ))}
                </div>
            )} */
