import React, { useState } from "react";
import { projectsData } from "../projectsData";

import "./gallery.css";
import Modal from "./modal";

const ImageGallery = ({ selectedProject }) => {
    const selectedProjectData = projectsData.find(
        (project) => project.title === selectedProject
    );

    const images = selectedProjectData ? selectedProjectData.images : [];
    const collab = selectedProjectData ? selectedProjectData.with : "";
    const video = selectedProjectData ? selectedProjectData.video : "";
    const text = selectedProjectData ? selectedProjectData.text : "";
    const curation = selectedProjectData ? selectedProjectData.curation : "";
    const location = selectedProjectData ? selectedProjectData.location : "";
    const link = selectedProjectData ? selectedProjectData.link : "";
    const description = selectedProjectData
        ? selectedProjectData.description
        : "";

    // State to track the index of the currently selected image
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const openModal = (index) => {
        setSelectedImageIndex(index);
    };

    const closeModal = () => {
        setSelectedImageIndex(null);
    };

    // Function to show the next image
    const showNextImage = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    // Function to show the previous image
    const showPreviousImage = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="gallery">
            <h2>
                <span className="project-title">{selectedProject}</span>
            </h2>

            {images && (
                <div className="scroller">
                    {images.length > 0 &&
                        images.map((image, index) => (
                            <div className="image-container" key={index}>
                                <img
                                    src={image.src}
                                    alt={image.description}
                                    onClick={() => openModal(index)}
                                />
                                {image.description && (
                                    <div
                                        className="image-description"
                                        dangerouslySetInnerHTML={{
                                            __html: image.description,
                                        }}
                                    ></div>
                                )}
                            </div>
                        ))}
                </div>
            )}

            {selectedImageIndex !== null && (
                <Modal
                    images={images}
                    selectedImageIndex={selectedImageIndex}
                    showPreviousImage={showPreviousImage}
                    showNextImage={showNextImage}
                    closeModal={closeModal}
                />
            )}
            {text && (
                <h2>
                    <span className="project-title">{selectedProject}</span>
                </h2>
            )}
            {collab && <h2>with {collab}</h2>}
            {location ? (
                <h2>
                    at
                    <a href={link} target="_blank" rel="noreferrer">
                        {location}
                    </a>
                </h2>
            ) : (
                <h2>{description}</h2>
            )}

            {text && (
                <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: text }}
                ></div>
            )}
            {video && (
                <div className="video-container">
                    <iframe
                        src={video[0].src}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    ></iframe>

                    {video[0].description && (
                        <div
                            className="video-description"
                            dangerouslySetInnerHTML={{
                                __html: video[0].description,
                            }}
                        ></div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ImageGallery;
