import React, { useState, useEffect } from "react";
import "./App.css";
import ProjectsList from "./components/projects";
import Nav from "./components/nav";
import ImageGallery from "./components/gallery";
import Bio from "./components/bio";
import IkuComponent from "./components/iku";
import Itch from "./components/theitch";

function App() {
    const [selectedProject, setSelectedProject] = useState(null);
    const [projectListVisible, setProjectListVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleProjectClick = (project) => {
        if (selectedProject === project) {
            // If the clicked project is the same as the selected project,
            // toggle the visibility of the project list
            setProjectListVisible(!projectListVisible);
        } else {
            setSelectedProject(project);
            setProjectListVisible(true);
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        setIsOpen(selectedProject === "Iku" || selectedProject === "The Itch");
    }, [selectedProject]);

    return (
        <div className="App">
            <Nav handleProjectClick={handleProjectClick} isOpen={isOpen} />

            {selectedProject === "Bio" ? (
                <Bio />
            ) : selectedProject === "Iku" ? (
                <IkuComponent selectedProject={selectedProject} />
            ) : selectedProject === "The Itch" ? (
                <Itch selectedProject={selectedProject} />
            ) : selectedProject ? (
                <ImageGallery selectedProject={selectedProject} />
            ) : projectListVisible ? (
                <ProjectsList handleProjectClick={handleProjectClick} />
            ) : (
                <div className="default-image-container">
                    {!selectedProject && (
                        <div className="default-images">
                            <img
                                src="/images/iku/iku27.png"
                                alt="Defaultimage"
                            />
                            <div className="image-description">
                                Graphic by Charlton Diaz
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default App;
