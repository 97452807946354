import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./nav.css";

const Nav = ({ handleProjectClick, isOpen }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuItemClick = () => {
        setIsMenuOpen(false); // Close the menu when a menu item is clicked
    };

    return (
        <div id="nav-component">
            <nav className={`nav ${isOpen ? "is-open" : ""}`}>
                <div className="johannaIku">
                    <Link to="/" onClick={() => handleProjectClick("")}>
                        {isOpen ? <h1>Iku</h1> : <h1>Johanna Odersky</h1>}
                    </Link>
                </div>
                <div className={`menu ${isMenuOpen ? "open" : ""}`}>
                    <Link
                        to=""
                        onClick={() => {
                            handleProjectClick("");
                            handleMenuItemClick(); // Close the menu when clicked
                        }}
                    >
                        <h2>SELECTED PROJECTS</h2>
                    </Link>
                    <Link
                        to="/about"
                        onClick={() => {
                            handleProjectClick("Bio");
                            handleMenuItemClick(); // Close the menu when clicked
                        }}
                    >
                        <h2>ABOUT</h2>{" "}
                    </Link>
                </div>
                <div
                    className={`menu-icon ${isMenuOpen ? "open" : ""}`}
                    onClick={handleMenuToggle}
                >
                    &#9776;
                </div>
            </nav>
        </div>
    );
};

export default Nav;
