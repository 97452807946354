const projectsData = [
    {
        id: 0,
        title: "Bio",

        text: [""],
    },
    {
        id: 1,
        title: `The Houses Of The Serpent Bearer, The 12th House`,
        with: "Motohiko Odani and Johanna Odersky",
        curation: "Pina Bendfeld",
        location: "Lucas Hirsch, Düsseldorf",

        dates: "June 10 – July 2, 2023",
        link: "http://lucashirsch.com/",
        cover: "/images/serpent/serpent1.jpg",
        images: [
            {
                src: "/images/serpent/serpent1.jpg",
                description:
                    "<em>Newfound Instincts</em>, 2019. Forged steel, confetti",
            },
            {
                src: "/images/serpent/serpent2.jpg",
                description: "Installation view",
            },
            {
                src: "/images/serpent/serpent3.jpg",
                description:
                    "<em>Time Keeper VII</em>, 2021. Watercolor on paper, frame made of thermoplastic, epoxy clay, glass. ",
            },
            {
                src: "/images/serpent/serpent4.jpg",
                description: "Installation view",
            },
            {
                src: "/images/serpent/serpent5.jpg",
                description: `<em>Landscape in Drag (score)</em>, 2022. Canvas, epoxy clay, steel rings, wood`,
            },
            {
                src: "/images/serpent/serpent6.jpg",
                description: `<em>Landscape in Drag (score)</em>, 2022. Canvas, epoxy clay, steel rings, wood`,
            },

            {
                src: "/images/serpent/serpent7.jpg",
                description:
                    "<em>A Path (score)</em>, 2022. Canvas, epoxy clay, steel rings, wood",
            },
            {
                src: "/images/serpent/serpent8.jpg",
                description: "Installation view",
            },

            {
                src: "/images/serpent/serpent9.jpg",
                description:
                    "<em>Rompers</em> by Motohiko Odani, 2003, video, 2 min 52 ",
            },

            {
                src: "/images/serpent/serpent10.png",
                description: `<em>Confinement in D minor</em>, 2021, video, 15min 45`,
            },
            {
                src: "/images/serpent/serpent11.jpg",
                description: `Screening of <em>Confinement in D minor</em>`,
            },
        ],
        video: [
            {
                src: "https://www.youtube.com/embed/BUMsTUL-kYQ",
                description: `<em>Confinement in D minor</em>, 2021, video performance, 15min 45`,
            },
        ],

        text: [
            `<h2>June 10 – July 2, 2023</h2><br>
            <h2>With a screening of the video  <em>Confinement in D minor</em> on June 29th.</h2><br></br>

           
        
            
            <em>»The weird thing is not wrong, after all: it is our conceptions that must be inadequate.« [1]</em> <br></br>
            
            In the 12th house of the cycle, the exhibition series „The Houses Of The Serpent Bearer“ presents wall pieces and a sculpture by the visual artist and musician Johanna Odersky along with the video work Rompers by the Tokyo-based artist Motohiko Odani. With their vibrant colours and recurring, curved forms, the works seem fun and vivacious, intensified by the cheerful soundtrack of the looping video. Yet at the same time, the subliminal feeling that something is wrong and weird slowly creeps in.<br></br>
            
            <em>»The sense of wrongness associated with the weird — the conviction that this does not belong — is often a sign that we are in the presence of the new. The weird here is a signal that the concepts and frameworks which we have previously employed are now obsolete.« [2]  </em><br></br>

            The duo exhibition by Odersky and Odani invites to a parallel universe, which shifts our notion of reality. Johanna Odersky‘s Time Keeper in the entrance area keeps the past, present and any possible future, thus providing a portal to another place where the concept of time no longer applies. We encounter the familiar, yet it appears strangely modified. Dancing and twirling confetti, a pair of scissors (Johanna Odersky, Newfound Instincts, 2019) celebrate their queerness and liberation from their passive existence as objects. <br></br>
            
            <em>»(...) the eerie is fundamentally tied up with questions of agency. What kind of agent is acting here? Is there an agent at all? These questions can be posed in a psychoanalytic register — if we are not who we think we are, what are we?« [3] </em><br></br>
            
            A mutation may have taken place, appearing in various forms. For example, Odani’s Rompers shows a girl sitting in a tree singing a song when she suddenly swallows a passing insect with her chameleon tongue. Her yellow reptile eyes try to hypnotise the viewer, while her head bobs innocently to the beat. Although the frogs grow a human ear on their backs, they circulate blithely around the pond – much like the sculptural scissors whirling around themselves. Also, the flies in Rompers are constantly forming a curved Moebius Eternity in the air. This shape reoccurs in Odersky‘s wall works, indicating an endless renewal and holistic perception. Formally, they are primarily inspired by baroque dance notations, which themselves somewhat function as a „time keeper“ by recording past events, predicting the future and influencing the present. [4]<br></br>

            <em>»scores have enabled us to reach out to other people, even across cultural and language barriers, and tell them what we would like to have happen. scores have made it possible, as well, for us to say to someone else what happened to us.« [5]</em><br></br>

            The formations or scores within the works of both artists communicate what our language system cannot capture. Instead, a rewriting of codes takes place, creating space for new narratives and storytelling. The supposed binarity of inside and outside dissolves, as does the human self-conception of identity and corporeality. Instead, like the scissors, we celebrate the grotesque, bizarre twists and the new weird.<br></br>

            <em>»(...) it is the human condition to be grotesque, since the human animal is the one that does not fit in, the freak of nature who has no place in the natural order and is capable of re-combining nature’s products into hideous new forms.« [6]</em><br></br>

            
            
            <span class="footnotes">
[1] Mark Fisher, The Weird And The Eerie, 2016, p. 26<br>
[2] ibid., p. 21<br>
[3] ibid., p. 17<br>
[4] Lawrence Halprin, RSVP Cycles: Creative Processes in the Human Environment, 1970<br>
[5] ibid.<br>
[6] Mark Fisher, The Weird And The Eerie, 2016, p. 77 – 78</span> <br></br>Curation and text by Pina Bendfeld<br>
            Hosted by Lucas Hirsch<br>
            Photos by Jana Buch.<br></br>
            `,
        ],
    },
    {
        id: 2,
        title: "The Itch",
        curation: "Steph Holl-Trieu",
        location: "Ashley, Berlin",
        dates: "April 14 – 16, 2023",
        link: "https://ashleyberlin.de/",
        cover: "/images/itch/itch16.jpg",
        images: [
            {
                src: "/images/itch/itch5.jpg",
                description: "The Itch, Installation view",
            },

            {
                src: "/images/itch/itch1.jpg",
                description: `Iku Live`,
            },

            {
                src: "/images/itch/itch0.jpg",
                description: `Installation view`,
            },
            {
                src: "/images/itch/itch3.jpg",
                description: "Installation view",
            },

            {
                src: "/images/itch/itch6.jpg",
                description: "Installation view",
            },
            {
                src: "/images/itch/itch2.jpg",
                description: "☆7571 Live",
            },

            {
                src: "/images/itch/itch7.jpg",
                description: "Iku live",
            },
            {
                src: "/images/itch/itch8.jpg",
                description: "Iku Live",
            },

            {
                src: "/images/itch/itch10.png",
                description: "Installation view",
            },
            {
                src: "/images/itch/itch14.jpg",
                description: "Installation view",
            },

            {
                src: "/images/itch/itch23.jpg",
                description: "Installation view",
            },
            {
                src: "/images/itch/itch16.jpg",
                description: "Installation view",
            },
            {
                src: "/images/itch/itch12.jpg",
                description: "Installation view",
            },
            {
                src: "/images/itch/itch13.jpg",
                description: "Iku Live",
            },
        ],
        video: [
            {
                src: "https://player.vimeo.com/video/848448567?h=504d08db4a",
                description: `<em>The Itch</em>, album release and showcase, video by François Pisapia`,
            },
        ],
        text: [
            `<h2>April 14 – 16, 2023</h2><br>
            <h2>Album launch with livesets set by Iku and ☆7571

</h2><br></br>

           
Ashley is proud to host the album release of “The Itch” by Berlin-based artist and musician Iku. Weaving wistful, introspective lyrics into a densely layered landscape of samples and sonic textures, “The Itch” interrupts reality and inserts an altered state of being—outside consensual time and space—in the here and now.<br></br>

If reality posits a stable relation between body, consciousness, and environment, “The Itch” longs for its reversal: An oscillating movement, floating without ground, piecing reality back together through melodically recurring memories. In another register, one would call this desire. In yet another, the virtual.<br></br>

Iku is a visual artist and musician based in Berlin. Her audio work layers associative samples into an entanglement of emotionally-charged sounds, creating a sensory mesh of hypnagogic piano progressions, evocative pop lyrics, and cut-up field recordings. She has been featured across different platforms and labels, including Creamcake (Berlin), AQNB (London/LA), and Amen (Vienna).<br></br>

☆7571 is a solo music project from Vienna that originated as an offspring of the collaborative effort UN’. Moving Internet concrète, field recordings and samples between freeware and contemporary dj methods, the project aims at manifesting the improvisational sensation of sampling into original sounding songs while merging distinguishable atmospheres. Recent releases include MixFiles and Tracks on Edited Arts, Club Paradiso and D e l e t e d.<br></br>

Text by Steph Holl-Trieu<br>
Video Documentation by François Pisapia<br>
Photo Documentation by Linus Muellerschoen<br>
 </br>

`,
        ],
    },

    {
        id: 3,
        title: "Memory Aids",
        location: "Shore Gallery, Vienna",
        dates: "November 4, 2022 – January 14, 2023",
        cover: "/images/Memory Aids/memory-aids1.jpg",

        link: "https://shore-gallery.eu/",
        images: [
            {
                src: "/images/Memory Aids/memory-aids1.jpg",
                description: "<em>Memory Aids</em>, installation view",
            },
            {
                src: "/images/Memory Aids/memory-aids2.jpg",
                description: "<em>Memory Aids</em>, installation view",
            },
            {
                src: "/images/Memory Aids/memory-aids3.jpg",
                description: `<em>Landscape in Drag (score)</em>, 2022. Canvas, epoxy clay, steel rings, wood`,
            },
            {
                src: "/images/Memory Aids/memory-aids4.jpg",
                description: `<em>A Path (score)</em>, 2022. Canvas, epoxy clay, steel rings, wood`,
            },
            {
                src: "/images/Memory Aids/memory-aids5.jpg",
                description: "<em>Memory Aids</em>, installation view",
            },
            {
                src: "/images/Memory Aids/memory-aids6.jpg",
                description:
                    "<em>World, Come Back to Me</em>, 2022, oil paint on folded canvas",
            },
            {
                src: "/images/Memory Aids/memory-aids7.jpg",
                description: `<em>As A Minor is to C</em>, 2022, bronze cast, steel rings, spotlights, LEDs, epoxy clay`,
            },
            {
                src: "/images/Memory Aids/memory-aids8.jpg",
                description: `<em>As A Minor is to C</em>, 2022, bronze cast, steel rings, spotlights, LEDs, epoxy clay`,
            },
            {
                src: "/images/Memory Aids/memory-aids9.jpg",
                description: `<em>As A Minor is to C</em> (detail), 2022, bronze cast, steel rings, spotlights, LEDs, epoxy clay`,
            },
            {
                src: "/images/Memory Aids/memory-aids10.jpg",
                description: `<em>As A Minor is to C</em> (detail), 2022, bronze cast, steel rings, spotlights, LEDs, epoxy clay`,
            },
        ],
        text: [
            `<span style="font-weight:1000">cream</span><br></br>
<span style="letter-spacing:-.03rem">
In the presence of an event or in its absence ___________ here, let it wash over you, here _____________<br>
.<br>
.<br>
Sugar mammal, slit throat<br>
Infinitely tender in your cadence<br>
Telling people you love them<br>
Selling them ice cream<br>
Holding their hands on an airplane<br>
Forgetting you’re not in Heaven<br>
Then forgetting you’re in Heaven<br>
Then forgetting nothing<br>
Remembering it all<br>
Forgetting this is what heaven feels like<br>
Like a manageable sucking<br>
Gentle<br></br>
August<br>
I gave pleasure to paintings 30.000 years ago<br>
I am etching “here” into my lover’s language, Here, I was here,<br>
her body changes the lines Here in these frames many voices speak, a cacophony and each is diffractively threaded through<br>
and enfolded in the other.<br></br>
May<br>
The social depends on more or less convenient agreements of how to measure space and time<br>
Lines are created as an effect of the repetition of certain walks and expressions<br>
Laugh lines, furrows through the fore<br>
head Here<br>
Here Here<br>
Well walked upon forest paths. But the paradox of the footprint is that lines are both created by being followed and<br>
followed by being created.<br></br>
June<br>
if memories are stored in the body, then to remember is nothing less than reincarnation.<br>
don’t disappear<br>
into my lips April<br>
It is by following some lines more than others that we acquire a sense of who we are.<br></br>
July<br>
Yesterday we sold out of pistachio, cookies and cream, matcha, earl grey lavender<br>
Body orients towards person with strands of curly, gray hair. i ask: waffle or wavers she says she hasn’t had wavers in<br>
fifteen years, proceeds to call the moment “prustian”. i have no idea what she is saying, respond “prustian ice cream<br>
temporalities,” she laughs approvingly, the line behind her is getting long, what would it be like to lick, where the ice<br>
cream goes it drip drops onto her collar bones her neck her fingertips i’d like to suck them gently make her giggle quick and<br>
sticky it is a 100 degrees, she says, whats your name on venmo, i want to tip you i say theres a tip jar right there, she says,<br>
but your name, i say yes<br>
yes<br>
yes<br>
three more times Here it smells good down Here would you like a sample<br></br>
Sugar mammal, open throat<br>
Tethered to the thickest part<br>
Life is full of far and close most of all<br>
it’s the breezy stuff that keeps me Here<br></br>
- a.Monti <br></br></span>`,
        ],
    },
    {
        id: 4,
        title: "Spin",
        location: "Sans titre, Paris",
        dates: "September 4 – October 16, 2021",

        cover: "/images/coverSpin3.jpg",

        link: "https://sanstitre.gallery/",
        images: [
            {
                src: "/images/spin/1.jpg",
                description: "<em>Spin</em>, installation view",
            },
            {
                src: "/images/spin/2.jpg",
                description:
                    "<em>Where Water Touches All Around</em> (detail), 2021<br> spray paint on rullers, styrofoam, thermoplastic, epoxy",
            },
            {
                src: "/images/spin/3.jpg",
                description:
                    "<em>Where Water Touches All Around</em> (detail), 2021<br> spray paint on rullers, styrofoam, thermoplastic, epoxy",
            },
            {
                src: "/images/spin/4.jpg",
                description: "<em>Spin</em>, installation view",
            },
            {
                src: "/images/spin/5.jpg",
                description:
                    "<em>Spin</em> (video still), 2021, video and sound, 20min 56 (in loop)",
            },

            {
                src: "/images/spin/9.jpg",
                description:
                    "<em>Spin</em> (video still), 2021, video and sound, 20min 56 (in loop)",
            },
            {
                src: "/images/spin/12.jpg",
                description: "<em>Spin</em>, installation view",
            },
            {
                src: "/images/spin/10.jpg",
                description:
                    "<em>Series of „Nows“</em>, 2021, Oil paint on folded canvas, metal rings",
            },

            {
                src: "/images/spin/15.jpg",
                description:
                    "<em>Finding Relief Underwater</em>, 2021, Oil paint on folded canvas, metal rings",
            },
            {
                src: "/images/spin/14.jpg",
                description:
                    "<em>Solitudinal Revolution</em>, 2021, Oil paint on folded canvas, metal rings",
            },
            {
                src: "/images/spin/13.jpg",
                description: "<em>Spin</em>, installation view",
            },
            {
                src: "/images/spin/18.jpg",
                description: "<em>Spin</em>, installation view",
            },

            {
                src: "/images/spin/17.jpg",
                description: "<em>Spin</em>, installation view",
            },
            {
                src: "/images/spin/16.jpg",
                description:
                    "<em>Representationalist Bed-Time Stories</em>, 2021, Watercolor on paper",
            },
        ],
        video: [
            {
                src: "https://www.youtube.com/embed/UWLekifzme0",
                description: `Excerpt from the video <em>Spin</em>, 2021, 20min 56 (in loop)`,
            },
        ],
        text: [
            `<h2>September 4 – October 16, 2021</h2><br></br>
         
            « No man ever steps in the same river twice, for it's not the same river and he's not the same man.» <br>− Heraclitus<br></br>

« Repetition is not generality. ...Reflections, echoes, doubles and souls do not belong to the domain of resemblance or equivalence; and it is no more possible to exchange one’s soul than it is to substitute real twins for one another. If exchange is the criterion of generality, theft and gift are those of repetition. There is, therefore, an economic difference between the two.»<br>
− Gilles Deleuze, Difference and Repetition<br></br>

Difference is forged, not given. Representation is no abstraction, no function mapping an already defined set of points to another; it’s a fabric of irreducibly multiple enactments of memory, foldings and unfoldings upon yet another umwelt, which must be played on their own terms, always resistant to the tactics of the last narrative, relentlessly working around distinctions rendered superficial, relics of a static regime of codes fixed in place as tautological permutations of a fully matured grammar.<br></br>

Life travels with the waves, intensities in themselves defining forces, ripples, currents, rhythms that inexorably comprise the raw vectors for their own notion of space, motion defined not by any coordinate system of “possibility” but as an annealing of prior materials, temperature turned up dangerously high, in order to create velocities, inextricable dyads of direction and magnitude, in lieu of points or distances: arrows predicated on nothing more and nothing less than markings drawn directly on a physical diagram, each a geometry knowing no equivalent to its passage of time. Turbulence becomes superfluity, a magma of functionalities spilling into wrinkles and crevices on the page, instantiating nascent clevages of subject and object as they collapse into channels of equivalence that cool down a congenitally unstable matter at hand into a laminar flow of relationships and compositional rules.<br></br>

Transmutation: from the ferality of ontology to the mathematics of sociality.<br></br>

Every story is rooted in such a conflict, its specific formalisms a lattice crystalizing around a focal point barely able to hold itself together, actors in search of a director bricolaging whatever catalysts they can from the incorrigibly physical substrate of the text in order to channel raw affective magnitudes into a passage from one state of affairs to another, into the ideation of a coextensive sense of direction. Wherever there’s conflict, there’s a synthetic a-priori ready to germinate from the ensuing story to be played out, an eleutheric leap to overcome dread through dogged tinkering, tidal gesticulations relentlessly separating and resynthesizing a substance that can only be peripherally glimpsed as the axis of this indefinite cycle.<br></br>

The relentless labor of the particular, eschewing all promises of certainty or comfort, craftsmanship simpliciter: the means of any and all differentiation.<br></br>

Using your hands to comprehend what’s right in front of you, to take constraints at face value and tacitly acknowledge that the only way out is through, to slog across that same river again and again: there is nothing “beyond” or “behind” the toil and joy of this eternal return. But when we respect this ostensible banality, when we keep moving along the surface of our world instead of lying down in resignation, our movements naturally compose into an idea of where we stand in relation to the stars in the sky as inhabitants of a larger universe, and in doing so bear witness to the way in which our never ending circles never entirely bring us back to where we started.<br></br>

− Text by Alex Boland`,
        ],
    },
    {
        id: 5,
        title: "Where Water Touches All Around",
        cover: "/images/WWTAA/1.jpg",

        location: "Les Urbaines, Lausanne",
        dates: "July 2 – 4, 2021",

        link: "https://www.urbaines.ch/",
        images: [
            {
                src: "/images/WWTAA/1.jpg",
                description:
                    "<em>Where Water Touches All Around</em>, 2021, spray paint on rulers, styrofoam, thermoplastic, epoxy",
            },
            {
                src: "/images/WWTAA/2.jpg",
                description: " <em>Where Water Touches All Around</em>, detail",
            },
            {
                src: "/images/WWTAA/3.jpg",
                description:
                    "<em>Where Water Touches All Around</em>, installation view",
            },
            {
                src: "/images/WWTAA/4.jpg",
                description:
                    "<em>Where Water Touches All Around</em>, installation view",
            },
            {
                src: "/images/WWTAA/5.jpg",
                description:
                    "<em>Where Water Touches All Around</em>, installation view",
            },
            {
                src: "/images/WWTAA/6.jpg",
                description:
                    "<em>Where Water Touches All Around</em>, installation view",
            },
            {
                src: "/images/WWTAA/8.jpg",
                description:
                    "<em>Where Water Touches All Around</em>, installation view",
            },
        ],
        video: [
            {
                src: "https://player.vimeo.com/video/582704115?h=b16f784c59",
                description: `<em>Where Water Touches All Around</em>, 2021, Public art project for Les Urbaines festival in Lausanne`,
            },
        ],
        text: [
            `<h2>July 2 – 4, 2021</h2><br>
            <h2>Public art project for Les Urbaines festival in Lausanne</h2><br></br>
            Over the course of three days, <em>Where Water Touches All Around </em>is installed on Lake Geneva and visible to all on the shore. The intricately structured form could be read as both an object and an event, both a site and as a situation. A floating piece of space, constructed as a spheric grid without discernible beginnings or endings. <br></br>
            Where Water Touches All Around is a commemorative piece. It encloses a web of personal associations and memories that unfold themselves in the public sphere. Anchored in the depths of the Lake, the installation reveals on the surface the essence of the links that our memory weaves between bodies, minds and places. <br></br>
<br></br>
Realized with the help of Rashiyah Elanga<br>
Photos by Cynthia Mai Ammann<br>
Video by : Rokhaya Marieme Balde & Raphaël Harari`,
        ],
    },
    {
        id: 6,
        title: "Voyeuse",
        location: "Galerie 1900-2000, Paris",
        dates: "April 2 – Mai 2, 2021",
        cover: "/images/coverVoyeuse2.jpg",

        link: "https://sanstitre.gallery/",
        images: [
            {
                src: "/images/voyeuse/1.jpg",
                description:
                    "<em>Voyeuse</em>, 2021, an offsite exhibition with Sans titre, Paris",
            },
            {
                src: "/images/voyeuse/2.jpg",
                description: "<em>Voyeuse</em>, installation view",
            },

            {
                src: "/images/voyeuse/4.jpg",
                description: "<em>Voyeuse</em>, installation view",
            },
            {
                src: "/images/voyeuse/5.jpg",
                description:
                    "<em>Study with Lozenges (Arms of an Unmarried Woman)</em>, 2021<br> colored pencil on rulers, thermoplastic, expoxy clay",
            },
            {
                src: "/images/voyeuse/6.jpg",
                description:
                    "<em>All Things Nocturnal</em>, 2021, Oil paint on folded canvas",
            },
            {
                src: "/images/voyeuse/7.jpg",
                description:
                    "<em>All Things Nocturnal</em>, 2021, Oil paint on folded canvas",
            },
            {
                src: "/images/voyeuse/8.jpg",
                description:
                    "<em>All Things Nocturnal</em> (detail), 2021, Oil paint on folded canvas",
            },
            {
                src: "/images/voyeuse/3.jpg",
                description: "<em>Voyeuse</em>, installation view",
            },
        ],
        text: [
            `<h2>2 April - 2 May 2021</h2><br>
            <h2>An offsite exhibition with Sans titre, Paris</h2><br></br>
            
The title of the exhibition references its distinctive setting. <em>Voyeuse</em> can only be observed from the road, revealing itself at all hours, day and night. It exposes itself to the gaze of spectators who may not have sought it out, akin to an accidental voyeur witnessing intimate moments not intended for their viewing.<br></br>

Specifically created for the shop window at 10 rue Bonaparte in Paris's 6th arrondissement, this new body of work continues the artist's interest in ways of measuring and representing time. <em>Voyeuse’s</em> arrangement can be likened to that of a musical score: it contains a spatial as well as a temporal plane.  With <em>All Things Nocturnal</em>, Odersky explores cyclical phenomena that define our relationship and understanding of time, such as the rhythms of day and night and the waxing and waning of the moon. The painting’s circular forms, fluid and adaptable, respond to the unfolding of events, delving into new modes of synchronization and harmony.<br></br>

The presence of the lozenge motif in <em>Study with Lozenges (Arms of an Unmarried Woman)</em> refers to the realm of heraldry, where its use denoted the marital status of a woman —single or widowed— signifying a destiny that was not linked to that of a man. These lozenges are composed of intertwined rulers, measuring devices par excellence. They appear as two shields, protective of the spiraling centerpiece of the composition.<br></br>
        Exbibition organized by Sans-titre, Paris<br>
        Photos by Margot Montigny and Ivan Murzin `,
        ],
    },
    {
        id: 7,
        title: "Time Keepers",
        location: "Intersticio, London",
        dates: "September 5 – October 17, 2020",

        cover: "/images/TK10.jpg",

        images: [
            {
                src: "/images/timekeepers/TK1.jpg",
                description: "<em>Time Keepers</em>, installation view",
            },
            {
                src: "/images/timekeepers/TK2.jpg",
                description: "<em>Time Keepers</em>, installation view",
            },
            {
                src: "/images/timekeepers/TK3.jpg",
                description: "<em>Time Keepers</em>, installation view",
            },
            {
                src: "/images/timekeepers/TK4.jpg",
                description:
                    "<em>Time Keeper I</em>, 2020, Watercolor on paper, frame made of thermoplastic, silk paper, steel, glas",
            },
            {
                src: "/images/timekeepers/TK5.jpg",
                description:
                    "<em>Time Keeper I</em> (detail), 2020, Watercolor on paper, frame made of thermoplastic, silk paper, steel, glas",
            },
            {
                src: "/images/timekeepers/TK6.jpg",
                description:
                    "<em>Time Keeper II</em>, 2020, Watercolor on paper, frame made of thermoplastic, silk paper, steel, glas",
            },
            {
                src: "/images/timekeepers/TK7.jpg",
                description:
                    "<em>Time Keeper II</em> (detail), 2020, Watercolor on paper, frame made of thermoplastic, silk paper, steel, glas",
            },
            {
                src: "/images/timekeepers/TK8.jpg",
                description:
                    "<em>Time Keeper III</em>, 2020, Watercolor on paper, frame made of thermoplastic, silk paper, steel, glas",
            },
            {
                src: "/images/timekeepers/TK9.jpg",
                description:
                    "<em>Time Keeper III</em> (detail), 2020, Watercolor on paper, frame made of thermoplastic, silk paper, steel, glas",
            },
            {
                src: "/images/timekeepers/TK10.jpg",
                description: "<em>Time Keepers</em>, installation view",
            },
            {
                src: "/images/timekeepers/TK11.jpg",
                description:
                    "<em>Types of Clouds II</em>, 2020, Oil paint on folded canvas",
            },
            {
                src: "/images/timekeepers/TK12.jpg",
                description:
                    "<em>Types of Clouds III</em>, 2020, Oil paint on folded canvas",
            },
            {
                src: "/images/timekeepers/TK13.jpg",
                description: "<em>Time Keepers</em>, installation view",
            },
            {
                src: "/images/timekeepers/TK14.jpg",
                description:
                    "<em>Types of Clouds I</em>, 2020, Oil paint on folded canvas",
            },
            {
                src: "/images/timekeepers/TK15.jpg",
                description:
                    "<em>Types of Clouds I</em>, 2020, Oil paint on folded canvas",
            },
        ],
        text: [
            `<h2>September 5 – October 17, 2020</h2><br></br><div>
            <em>A Song for Iku:<br></em><br>
            Thoughts whirl out of control—perfectly shaped circles carved off-center make me wonder what holds me in place. I try to imagine the cosmos entirely; fail and remember your body is my body.<br><br>
            Longitudinal grids spiral into each other. Are they silent sound waves turning and turning? Will they crash and splinter or merge smoothly like clouds?<br><br>
            Concentric circles curl into landscapes out of the frame while I feel a slight thump on my chest.<br><br>
            I know that “change requires forgetting” [1] 
            and the feeling of loss I always bring along makes itself comfortable on the floor. A spot is left blank and I feel the silence. Cut sound “constitutes a kind of promise.” [2]<br><br>
            I want a map that tells me what and how I felt when and why.<br><br>
            <em>Time Keepers </em>offers insight into the process of representing memory. Similar to how a partiture abstracts sound through signs, the exhibited artworks condense duration into color. The affective registers attached to specific memories are transmuted into haptic scores that invite a transformative awareness of time.<br><br>
            Everything changes, nothing stays put and this is called hope. Your eyes widen and the map of our memories “is not a stable representation of a more or less unchanging landscape.” [3] The ground was always porous.<br><br>
            I look again and try to locate the knot growing at my navel. It slowly grows at an angle hiding under folds of skin while “naked flesh is bound.” [4]<br><br>The temporal dimension of affect is the substance of <em>Time Keepers. </em>These cartographies of inner experience co-exist—as in memory—through their unmeasurable mutuality and mutability, which reminds me how the color wheel feels vast like water: cyclical and repetitive yet timeless.<br><br>You are on the move again. The wind ruffling your feathers fills the room.<br><br>Are you feeling free today?<br><br>Is time clipping your wings afresh?<br><br>I pour an ocean onto the map with one immortal hand behind my back<br><br>while the other writes songs you forgot.<br><br>A ring inside a ring welds the thought crust of our memories together.<br><br>Alone in the unriddled sea, we forget our secrets and seduce our sorrows.<br><br>— José B. Segebre<br><br>
           
<span class="footnotes">[1] Catriona Mortimer-Sandlands, “Landscape, Memory, and Forgetting: Thinking Through (My Mother's) Body and Place,” in <em>Material Feminisms </em>ed. Stacy Alaimo and Susan Hekman (Bloomington: Indiana University Press, 2008), 282.<br><br>[2] Carlos Basuado, “Score,” in <em>In Terms of Performance</em>. ed. Shannon Jackson and Paula Marincol (Berkeley: Arts Research Center at UC Berkeley, 2016), Accesed August 23, 2020: http://intermsofperformance.site/keywords/score/carlos-basualdo.<br><br>[3] Jonathan Flatley, <em>Affective Mapping </em>(Cambridge: Harvard University Press, 2008), 7.<br><br>[4] Elizabeth Freeman, <em>Time Binds: Queer Temporalities, Queer Histories </em>(Durham: Duke University Press, 2010), 7.</span><br></br> Curated by Cristina Herraiz Peleteiro<br>
Photos by Theo Christelis<br></br>`,
        ],
    },
    {
        id: 8,
        title: "Folded Paintings Series",

        cover: "/images/folded/15.jpg",
        dates: "2020 – 2022",

        images: [
            {
                src: "/images/folded/1.jpg",
                description:
                    "<em>Topographical Souvenir II</em>, 2020, oil on folded canvas, 90 x 95 cm",
            },
            {
                src: "/images/folded/2.jpg",
                description:
                    "<em>Topographical Souvenir II</em>, 2020, oil on folded canvas, 90 x 95 cm",
            },
            {
                src: "/images/folded/3.jpg",
                description:
                    "<em>Topographical Souvenir II</em> (detail), 2020, oil on folded canvas, 90 x 95 cm",
            },
            {
                src: "/images/folded/4.jpg",
                description:
                    "<em>Echolalia</em>, 2021, oil on folded canvas, 85 x 90 cm",
            },
            {
                src: "/images/folded/5a.jpg",
                description:
                    "<em>Echolalia</em> (detail), 2021, oil on folded canvas, 85 x 90 cm",
            },
            {
                src: "/images/folded/5.jpg",
                description:
                    "<em>Echolalia</em> (detail), 2021, oil on folded canvas, 85 x 90 cm",
            },

            {
                src: "/images/folded/6.jpg",
                description:
                    "<em>Echolalia</em>, 2021, oil on folded canvas, 85 x 90 cm",
            },
            {
                src: "/images/folded/7.jpg",
                description:
                    "<em>Topographical Souvenir I</em>, 2021, oil paint on folded canvas, 63 x 60 cm",
            },
            {
                src: "/images/folded/8.jpg",
                description:
                    "<em>Topographical Souvenir I</em> (detail), 2021, oil paint on folded canvas, 63 x 60 cm",
            },
            {
                src: "/images/folded/9.jpg",
                description:
                    "<em>Topographical Souvenir I</em>, 2021, pictured as shown in <em>Skyward</em> with Pedro Herrero Ferrán",
            },
            {
                src: "/images/folded/10.jpg",
                description:
                    "<em>All Things Nocturnal</em> (detail), 2021, oil paint on folded canvas, 62 x 56 cm",
            },
            {
                src: "/images/folded/11.jpg",
                description:
                    "<em>All Things Nocturnal</em>, 2021, oil paint on folded canvas, 62 x 56 cm",
            },
            {
                src: "/images/folded/12.jpg",
                description:
                    "<em>All Things Nocturnal</em>, 2021, Oil paint on folded canvas, 62 x 56 cm",
            },
            {
                src: "/images/folded/13.jpg",
                description:
                    "<em>Adrift in Time With No Land in Sight</em>, 2020, oil on folded canvas, 70 x 65 cm",
            },
            {
                src: "/images/folded/14.jpg",
                description:
                    "<em>Adrift in Time With No Land in Sight</em>, 2020, oil on folded canvas, 70 x 65 cm",
            },
            {
                src: "/images/folded/15.jpg",
                description:
                    "<em>Adrift in Time With No Land in Sight</em> (detail), 2020, oil on folded canvas, 70 x 65 cm",
            },
        ],
        text: `
        Falten, Raffungen und pastose Farbverläufe kennzeichnen die reliefartigen Malereien von Johanna Odersky. Die runden Leinwände erscheinen wie schwere barocke Krägen oder in Falten geworfene Roben, auf denen wiederholt Kreisformen auftauchen, die in immer noch größeren Kreisen aufgehen. Die Formensprache ebenso wie die pastellige Farbigkeit der Werke verweisen auf zyklische Abfolgen wie Tag und Nacht, Frühling und Winter, den zunehmenden und abnehmenden Mond.<br></br>
Die Künstlerin hinterfragt in ihren Werken, wie Wiederholung genutzt wird, um das Vergehen von Zeit visuell nachvollziehbar zu machen. Dafür orientiert sie sich an mathematischen und musiktheoretischen Konzepten, um zu erforschen, wie Dissonanz, Harmonie oder Synchronisation entstehen kann, wenn verschiedene aufeinanderfolgende Abläufe des täglichen Lebens miteinander verschmelzen, oder wie sie durch Veränderungen und Ereignisse gestört oder ausgedehnt werden können.[1] Ein Großteil ihrer Arbeit dreht sich um die Frage, wie menschliche Erfahrung organisiert und verkörpert wird und inwiefern die Beziehungen zwischen Körper, Geist und Außenwelt nur im Kontext struktureller Machtverhältnisse zu verstehen sind.<br></br>
Apology (reworked), 2022 und Topographical Souvenir II, 2021 können als Versuche verstanden werden, das Verhältnis zwischen der uns umgebenden, natürlichen Welt und unseren persönlichen wie auch sozialen Verhaltensweisen aufzuzeigen, indem sie diese auf abstrakte und zugleich sinnliche Weise organisieren und visuell erfahrbar machen. Dabei haben die Werke keine Funktion – wie etwa meteorologische Karten oder Kalender – außer ihrer ästhetischen Wirkkraft. Hier zeigt sich das Interesse der Künstlerin an Figuration und sensorischer Abstraktion, wobei sie in Anlehnung an Kunstströmungen wie die Pattern & Decoration-Bewegung[2] untersucht, wie diese die Art und Weise beeinflussen, wie wir die Realität wahrnehmen und wie sich unser Wissen ebenso wie unsere (ästhetischen) Erfahrungen in unseren Körper und unseren Habitus einschreiben.<br></br>
Gilles Deleuze beschäftigte sich in seinem 1988 erschienenen Buch „Die Falte. Leibniz und der Barock“ mit der Falte als Sinnbild für einen sich im ewigen Werden befindlichen Prozess, der sich zwischen dem Seelischem, dem Leiblichen und einem ereignishaften Außen vollzieht. Der Versuch der Bestimmung dieses Prozesses und der Erörterung der Unterschiede zwischen seinen Beteiligten (Körper, Geist und Außenwelt), bedeutet wiederum eine Entfaltung, die sich ebenfalls unendlich weiter treiben ließe, ohne den Unterschied zwischen ihnen tatsächlich jemals auflösen zu können.[3] Diese Unauflösbarkeit der Verflechtungen, das Verstörende ebenso wie das Vergnügen daran, bilden sich in der Dynamik, den Formen, Räumen und Spannungen der Arbeiten von Johanna Odersky ab: „Die Gedanken geraten außer Kontrolle – perfekt geformte Kreise, die außerhalb der Mitte liegen, lassen mich fragen, was es ist, das mich festhält? Ich versuche, mir den Kosmos vollständig vorzustellen, scheitere und denke einfach daran, dass dein Körper mein Körper ist.“[4] <br></br>
 <span class="footnotes">
[1] Repetitions, rhythms and the running of time. Interview with Johanna Odersky, Marta Garcia De Faria, Dateagle Art
2020<br>
[2] Pattern & Decoration war eine Kunstströmung der 1970-1980er Jahre in den USA, in der der Anteil von Frauen so
hoch war wie in keiner künstlerischen Bewegung zuvor. Ihre Anhänger*innen hoben sich mit ihrer Kunst radikal von der
vorangegangenen puristischen Minimal Art und rationalistischen Konzeptkunst ab, indem sie Fantasie, Farbe,
Formenvielfalt und das Affektive in die Kunst zurückholten. Dabei hinterfragten sie nicht nur tradierte Vorstellungen von
Kunst, sondern thematisierten auch politische Fragen wie den Stellenwert von Frauen, den amerikanischen
Ureinwohner*innen oder von ethnischen Minderheiten im Kunstbetrieb und in der Gesellschaft. Pattern & Decoration
proklamierte damit einen Gegenentwurf zu einem Kunstbegriff, der weltweit von Werten der westlichen Industriestaaten
bestimmt und männlich dominiert ist. Ihren politisch-globalen Anspruch artikulierte die Bewegung durch eine Ästhetik
mitreißender Leichtigkeit und verführerischer Schönheit: mit Arbeiten die Sinnlichkeit, Fantasie und Farbe feiern und in
denen gleichermaßen sozialkritische Inhalte und das Erlebnis unmittelbarer Lebensfreude transportiert werden. (Pattern
and Decoration, Esther Boehle, 2019)<br>
[3] Die Falte zwischen Leib und Seele, Christian Schlueter, 1995<br>
[4] A Song for Iku, José B. Segebre, 2020<br></br>
</span>
<h2>Text by Pia-Marie Remmers<br> written for the exhibition <a href="https://www.kunsthallelingen.de/alle-farben-fixieren-chromosomale-glieder-11-marz-7-mai-2023/"><em>Alle
Farben fixieren chromosomale Glieder</em> at Kunsthalle Lingen</a></h2><br></br>
`,
    },

    {
        id: 11,
        title: "Iku",
        description: "Music and performance project",
        cover: "/images/coverIku.jpg",
        dates: "2015 – ongoing",

        images: [
            {
                src: "/images/iku/Iku1.jpeg",
                description: `<em>The Itch Live</em>, 2021-ongoing, 45 min, Audiovisual performance realized in collaboration with FAUN TV / François Pisapia.<br>
Pictured as shown during the Lemaniana Performance festival at Arsenic, Lausanne`,
            },
            {
                src: "/images/iku/Iku2.jpg",
                url: "https://vimeo.com/790145653",
            },
            {
                src: "/images/iku/Iku3.JPG",
                description:
                    "<em>Iku Live</em> during <em>Ruffles</em> at Joanne, Frankfurt am Main",
            },
            {
                src: "/images/iku/Iku4.jpg",
                description: `<em>The Itch Live</em>, 2021-ongoing, 45 min, Audiovisual performance realized in collaboration with FAUN TV / François Pisapia.<br>
Pictured as shown during the Performance program of <em>SOMETHING IS BURNING</em> at Kunsthalle Bratislava`,
            },
            {
                src: "/images/iku/Iku5.jpg",
                url: "https://iku.hearnow.com/",
            },
            {
                src: "/images/iku/Iku6.jpg",
                description: `<em>The Itch Live</em>, 2021-ongoing, 45 min, Audiovisual performance realized in collaboration with FAUN TV / François Pisapia.<br>
Pictured as shown during the Performance program of <em>SOMETHING IS BURNING</em> at Kunsthalle Bratislava`,
            },
            {
                src: "/images/iku/Iku7.jpg",
                description: `<em>The Itch Live</em>, 2021-ongoing, 45 min, Audiovisual performance realized in collaboration with FAUN TV / François Pisapia.<br>
Pictured as shown during the Performance program of <em>SOMETHING IS BURNING</em> at Kunsthalle Bratislava`,
            },
            {
                src: "/images/iku/Iku8.jpg",
                url: "https://vimeo.com/754753801",
            },
            {
                src: "/images/iku/Iku9.jpg",
                description: `<em>The Itch Live</em>, 2021-ongoing, 45 min, Audiovisual performance realized in collaboration with FAUN TV / François Pisapia.<br>
Pictured as shown during the Performance program of <em>SOMETHING IS BURNING</em> at Kunsthalle Bratislava`,
            },
            {
                src: "/images/iku/Iku10.jpg",
                description: `<em>The Itch Live</em>, 2021-ongoing, 45 min, Audiovisual performance realized in collaboration with FAUN TV / François Pisapia.<br>
Pictured as shown during the Lemaniana Performance festival at Arsenic, Lausanne`,
            },
            {
                src: "/images/iku/Iku11.png",
                url: "https://www.youtube.com/watch?v=BUMsTUL-kYQ",
            },
            {
                src: "/images/iku/iku12.jpg",
                url: "https://vimeo.com/790145653",
            },
            {
                src: "/images/iku/Iku13.png",
                url: "https://www.youtube.com/watch?v=BUMsTUL-kYQ",
            },
            {
                src: "/images/iku/Iku14.png",
                url: "https://www.youtube.com/watch?v=BUMsTUL-kYQ",
            },
            {
                src: "/images/iku/Iku15.png",
                url: "https://www.youtube.com/watch?v=BUMsTUL-kYQ",
            },
            {
                src: "/images/iku/Iku16.jpg",
                url: "https://www.youtube.com/watch?v=wUxBKB2Tuew",
            },
            {
                src: "/images/iku/Iku17.png",
                url: "https://www.youtube.com/watch?v=wUxBKB2Tuew",
            },
            {
                src: "/images/iku/Iku18.png",
                url: "https://www.youtube.com/watch?v=wUxBKB2Tuew",
            },
            {
                src: "/images/iku/Iku19.png",
                url: "https://www.youtube.com/watch?v=wUxBKB2Tuew",
            },
            {
                src: "/images/iku/iku20.jpg",
                url: "https://soundcloud.com/ikukojohanna",
            },
            {
                src: "/images/iku/iku21.jpg",
                url: "https://www.youtube.com/embed/Onx_4Y_BsaY",
            },
            {
                src: "/images/iku/iku22.jpg",
                url: "https://www.youtube.com/embed/Onx_4Y_BsaY",
            },

            {
                src: "/images/iku/Iku23.png",
                url: "https://www.youtube.com/watch?v=wUxBKB2Tuew",
            },
            {
                src: "/images/iku/Iku24.jpg",
                url: "https://ikukojohanna.bandcamp.com/track/sweet-days-of-discipline",
            },
            {
                src: "/images/iku/Iku25.JPG",
                description:
                    "<em>Iku Live</em> during <em>3hd 2020’s “Dune”</em>, Kleiner Wasserspeicher, Berlin",
            },
            {
                src: "/images/iku/Iku26.jpg",
                url: "https://1000amen.bandcamp.com/album/iku",
            },
            {
                src: "/images/iku/iku27.png",
                url: "https://ikukojohanna.bandcamp.com/track/personal-horizon",
            },
            {
                src: "/images/iku/Iku28.jpeg",
                url: "https://creamcake.bandcamp.com/album/body-horror",
            },
            {
                src: "/images/iku/Iku29.png",
                url: "https://www.youtube.com/watch?v=BUMsTUL-kYQ",
            },
            {
                src: "/images/iku/Iku30.png",
                url: "https://ikukojohanna.bandcamp.com/",
            },
            {
                src: "/images/iku/Iku31.JPG",
                description:
                    "<em>Iku Live</em>, 2019, at ExZess, Frankfurt am Main",
            },
            {
                src: "/images/iku/Iku32.jpg",
                description:
                    "<em>Iku Live</em>, 2019, at Creepy Teepee festival, Kutna Hora.",
            },
            {
                src: "/images/iku/Iku33.png",
                url: "https://vimeo.com/754753801",
            },
            {
                src: "/images/iku/Iku34.jpg",
                url: "https://genot.bandcamp.com/album/fugue-some-temporal-patterns-other-than-the-forward-march",
            },
            {
                src: "/images/iku/Iku35.jpg",
                url: "https://genot.bandcamp.com/album/fugue-some-temporal-patterns-other-than-the-forward-march",
            },
            {
                src: "/images/iku/Iku36.jpg",
                url: "https://ikukojohanna.bandcamp.com/merch/the-itch-merch",
            },
            {
                src: "/images/iku/Iku37.jpg",
                url: "https://ikukojohanna.bandcamp.com/merch/the-itch-merch",
            },
            {
                src: "/images/iku/Iku38.png",
                description: "<em>Iku Live</em>, 2019, at [...]Raum, Witten.",
            },
            {
                src: "/images/iku/Iku39.jpeg",
                url: "https://pw-magazine.com/2018/iku-escaping-the-binary-of-success-and-failure",
            },
            {
                src: "/images/iku/iku40.jpg",
                description:
                    "<em>Iku Live</em>, 2023, during <em>The Itch - album launch</em> at Ashley, Berlin",
            },

            {
                src: "/images/iku/iku41.jpg",
                description:
                    "<em>Iku Live</em>, 2021, at Les Urbaines festival, Lausanne",
            },
            {
                src: "/images/iku/Iku43.jpg",
                url: "http://www.fromheretillnow.com/",
            },
            {
                src: "/images/iku/Iku44.png",
                description: "The Itch - album launch</em> at Ashley, Berlin",
            },
            {
                src: "/images/iku/Iku45.jpg",
                url: "http://www.fromheretillnow.com/",
            },
        ],

        text: [
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet,",
        ],
    },
];

export { projectsData };
